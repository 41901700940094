import React, { useMemo, useState, memo } from 'react';
import NextLink from 'next/link';
import {
    StyledLinkButton,
    StyledLinkWrapper,
    StyledMenuColumn,
    StyledMenuImage,
    StyledMenuSectionTitleLink,
    StyledSectionButtonWrapper,
} from './styled';
import { Text, Image, Button, ImageLink } from '~/shared/components';
import { ImageMedia, MenuSubSection } from '~/lib/data-contract';
import { useTranslation } from '$shared/utils/translation';
import { timingMedium, easeOutCirc } from '~/theme/animations/baseAnimations';
import { queries } from '~/theme/breakpoints';
export type Props = {
    menuSection: MenuSubSection;
    numberOfSections: number;
};

export const _MenuSection = ({ menuSection, numberOfSections }: Props) => {
    const links = menuSection.subMenuItems ?? [];
    const defaultImage = menuSection.image?.src ? menuSection.image : undefined;
    const [currentImage, setCurrentImage] = useState(defaultImage);
    const { translate } = useTranslation();

    const allImages = useMemo(() => {
        const images = links
            .filter((link) => !!link?.image?.src)
            .map((link) => {
                return link?.image;
            }) as ImageMedia[];
        if (defaultImage) {
            images.push(defaultImage);
        }
        const uniqueImages = images.filter((image, index, self) => {
            return self.findIndex((item) => item.src == image.src) == index;
        });
        return uniqueImages;
    }, [defaultImage, links]);

    const menuSectionTitleMemo = useMemo(() => {
        const title =
            menuSection.title ||
            menuSection.pageReference?.title ||
            menuSection.pageReference?.text;
        if (!title) return <></>;
        const titleText = <Text variant="display4" children={title} />;

        if (menuSection.pageReference?.url) {
            return (
                <NextLink href={menuSection.pageReference.url} passHref legacyBehavior>
                    <StyledMenuSectionTitleLink>{titleText}</StyledMenuSectionTitleLink>
                </NextLink>
            );
        }

        return titleText;
    }, [menuSection.pageReference, menuSection.title]);

    const imageSizes: { [key: number]: string } = useMemo(() => {
        return {
            2: `${queries.lg} 500px, ${queries.md} 440px, ${queries.sm} 360px`,
            3: ` ${queries.lg} 340px, ${queries.md} 290px, ${queries.sm} 240px`,
        };
    }, []);

    return (
        <StyledMenuColumn>
            <StyledMenuImage isLarge={numberOfSections === 2}>
                {typeof menuSection?.pageReference?.url != 'undefined'
                    ? allImages.map((image, index) => (
                          <ImageLink
                              aria-hidden
                              withBorderRadius
                              animationDuration={600}
                              css={{
                                  transition: `opacity ${timingMedium} ${easeOutCirc}`,
                                  opacity: image?.src == currentImage?.src ? 1 : 0,
                                  zIndex: image?.src == currentImage?.src ? 2 : 1,
                              }}
                              key={index}
                              linkProps={{
                                  href: `${menuSection.pageReference?.url}`,
                                  prefetch: false,
                              }}
                              imageProps={{
                                  ...image,
                                  objectFit: 'cover',
                                  layout: 'fill',
                                  draggable: false,
                                  sizes: imageSizes?.[numberOfSections],
                              }}
                          />
                      ))
                    : allImages.map((image, index) => (
                          <Image
                              aria-hidden
                              key={index}
                              {...image}
                              objectFit="cover"
                              layout="fill"
                              draggable={false}
                              css={{
                                  transition: `opacity ${timingMedium} ${easeOutCirc}`,
                                  opacity: image?.src == currentImage?.src ? 1 : 0,
                                  zIndex: image?.src == currentImage?.src ? 2 : 1,
                              }}
                          />
                      ))}
            </StyledMenuImage>
            {menuSectionTitleMemo}
            <StyledLinkWrapper>
                {links.map(
                    (menuItem, index) =>
                        menuItem.url.url && (
                            <li key={menuItem.url.url + index}>
                                <NextLink
                                    href={menuItem.url.url}
                                    passHref
                                    key={menuItem.url.url + index}
                                    prefetch={false}
                                    legacyBehavior
                                >
                                    <StyledLinkButton
                                        as="a"
                                        title={menuItem.title || menuItem.url.title}
                                        href={menuItem.url.url}
                                        children={menuItem.title || menuItem.url.text}
                                        variant="Plain"
                                        iconAlignment="start"
                                        icon="Arrow Right"
                                        style={{ justifyContent: 'flex-start' }}
                                        onMouseOver={() => {
                                            if (!menuItem.image) return;
                                            setCurrentImage(menuItem.image);
                                        }}
                                        onMouseLeave={() => setCurrentImage(defaultImage)}
                                    />
                                </NextLink>
                            </li>
                        ),
                )}
                {menuSection.pageReference?.title && menuSection.pageReference?.url && (
                    <StyledSectionButtonWrapper>
                        <NextLink
                            href={menuSection.pageReference.url}
                            passHref
                            prefetch={false}
                            legacyBehavior
                        >
                            <Button
                                title={menuSection.pageReference.title}
                                children={
                                    translate('Kompan.MainMenu.SubSectionLinkLabel') ||
                                    menuSection.pageReference.title
                                }
                                variant="Plain"
                                style={{ whiteSpace: 'normal', justifyContent: 'flex-start' }}
                            />
                        </NextLink>
                    </StyledSectionButtonWrapper>
                )}
            </StyledLinkWrapper>
        </StyledMenuColumn>
    );
};

export const MenuSection = memo(_MenuSection);
