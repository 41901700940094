import { GetServerSidePropsContext } from 'next';
import { useEffect, useMemo } from 'react';
import { Frame, M10HeroModule, Modules, Pages, PageElement } from '~/lib/data-contract';
import { getPageProps, pageResolver } from '~/templates/pages/utils';
import { PageProvider } from '$templates/pages';
import { Layout } from '../Layout';
import { productPageAndDataResolver } from '$templates/pages/utils';
import { MarketSelectorDrawer } from '~/shared/components/MarketSelector';
import { DehydratedState } from 'react-query';
import { useRouter } from 'next/router';
import { KompanGetQuotePanel, useStructuredData, useTracking } from '$shared/components';
import { isSSR } from '~/shared/utils';
import { useCookieConsent, CookieConsentSettings } from '~/shared/hooks/useCookieConsent';
import { LazyRecaptcha } from '$shared/utils/lazy-recaptcha';
import { InViewContextWrapper } from '$shared/utils/in-view';
import { productDetailPageUrlRegex } from '../P70ProductDetailsPageTemplate/utils/productDetailPageUrlRegex';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import { useAuth } from '~/features/authentication';
import { myKompanLoginRedirectPageUrlFallback } from '~/lib/constants';
import dynamic from 'next/dynamic';
import { usePrevious } from 'react-use';

const P70ProductDetailsPage = dynamic(() =>
    import('../P70ProductDetailsPageTemplate').then((mod) => mod.P70ProductDetailsPage),
);
const P80SearchResultsPage = dynamic(() =>
    import('../P80SearchResultsPage').then((mod) => mod.P80SearchResultsPage),
);
const P140NotFoundPage = dynamic(() =>
    import('../P140NotFoundPage').then((mod) => mod.P140NotFoundPage),
);
const P401UnauthorizedPage = dynamic(() =>
    import('../P401UnauthorizedPage').then((mod) => mod.P401UnauthorizedPage),
);
const P410StaticNotFoundPage = dynamic(() =>
    import('../P410StaticNotFoundPage').then((mod) => mod.P410StaticNotFoundPage),
);

const P90FavoritePageTemplate = dynamic(() =>
    import('../P90FavoritePageTemplate').then((mod) => mod.P90FavoritePageTemplate),
);

const P100RecommendationsFlowPageTemplate = dynamic(() =>
    import('../p100RecommendationsFlowPageTemplate').then(
        (mod) => mod.P100RecommendationsFlowPageTemplate,
    ),
);
const P60ModulePage = dynamic(() => import('../P60ModulePage').then((mod) => mod.P60ModulePage));
const DynamicBlockList = dynamic(() =>
    import('~/templates/blocks').then((mod) => mod.DynamicBlockList),
);

const myKompanModulesList = [
    'myKompanLoginModule',
    'myKompanLoginRedirectModule',
    'myKompanSalesDocumentModule',
    'myKompanSalesDocumentProductModule',
    'myKompanFeaturePlannerModule',
];

const Page = ({ page }: { page: Pages }) => {
    switch (page.type) {
        case 'p100RecommendationsFlows':
            return <P100RecommendationsFlowPageTemplate {...page} />;
        case 'p60ModulePage':
            return <P60ModulePage {...page} />;

        case 'p80SearchResultsPage':
            return <P80SearchResultsPage {...page} />;
        case 'p140NotFoundPage':
            return <P140NotFoundPage {...page} />;
        case 'p70ProductDetailsPageTemplate':
            return <P70ProductDetailsPage />;
        case 'p90FavoritePageTemplate':
            return <P90FavoritePageTemplate {...page} />;
        case 'p401UnauthorizedPage':
            return <P401UnauthorizedPage {...page} />;
        case 'p410StaticNotFoundPage':
            return <P410StaticNotFoundPage {...page} />;
    }

    return <DynamicBlockList elements={(page?.pageElements as Modules[]) || []} />;
};

export type DynamicPageProps = {
    page: Pages;
    frame?: Frame;
    dehydratedState?: DehydratedState;
    settings: {
        isMyKompan: boolean;
        isLocalhost: boolean;
        hasMyKompanModule: boolean;
        pageBaseUrl: string;
        pageUrl: string;
        aiInstrumentationKey?: string | null;
        googleRecaptchaKey?: string | null;
    };
};

export const DynamicPage = (props: DynamicPageProps) => {
    const router = useRouter();
    const previousAsPath = usePrevious(router.asPath);
    const { trackPageView } = useTracking();
    const { setOrganizationSchema } = useStructuredData();
    const { page, frame, settings } = props;
    const { isMyKompan, pageUrl, pageBaseUrl, isLocalhost, googleRecaptchaKey } = settings;
    const { setConsent, setInitialMarketingParams } = useCookieConsent();
    const { trackContentView } = useRelewiseTracking();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isSSR) {
            window.addEventListener('CookieInformationConsentGiven', onCookieConsentChange, false);
        }

        setInitialMarketingParams();

        return () => {
            window.removeEventListener('CookieInformationConsentGiven', onCookieConsentChange);
        };
    }, []);

    useEffect(() => {
        if (page?.type === 'p20FrontPage' && frame) {
            setOrganizationSchema({
                '@type': frame?.structuredDataOrganization?.organizationType,
                name: frame?.structuredDataOrganization?.organizationName || '',
                alternateName: frame?.structuredDataOrganization?.organizationAlternateName || '',
                url: frame?.structuredDataOrganization?.organizationUrl || '',
                logo: frame?.structuredDataOrganization?.organizationLogo || '',
                sameAs: frame?.structuredDataOrganization?.organizationSameAsItems || [],
            });
        }
    }, [page?.type, frame]);

    const onCookieConsentChange = () => {
        const consentOptions: CookieConsentSettings = {
            functional:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_functional') == true,
            marketing:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing') == true,
            required:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_preferences') == true,
            statistics:
                window?.CookieInformation?.getConsentGivenFor('cookie_cat_statistic') == true,
        };
        setConsent(consentOptions);
    };

    useEffect(() => {
        if (!page) return;
        // If is MyKompan and reaching frontpage, redirect user to either loginPage or dashboard
        if (isMyKompan && page?.type == 'p20FrontPage') {
            const dashboardPageUrl = frame?.staticLinks?.myKompanDashboardPage?.url;
            const loginPageUrl = frame?.staticLinks?.myKompanLoginPage?.url;
            const targetUrl = isAuthenticated && dashboardPageUrl ? dashboardPageUrl : loginPageUrl;
            router.push(targetUrl || myKompanLoginRedirectPageUrlFallback);
        }
    }, [page, frame, isMyKompan, isAuthenticated]);

    useEffect(() => {
        const isNewRoute = router.asPath?.split('?')?.[0] !== previousAsPath?.split('?')?.[0];
        if (page.type !== 'p70ProductDetailsPageTemplate' && isNewRoute) {
            trackPageView({
                page_type: page.type,
            });
        }
        if (page?.type == 'p60ModulePage' && isNewRoute) {
            trackContentView(page.id);
        }
    }, [router.asPath, previousAsPath]);

    const pageElements = useMemo(() => {
        if (!page.pageElements) return [];

        return page.pageElements.map(
            (element, index) => ({ ...element, pageElementIndex: index }) as PageElement,
        );
    }, [page]);

    const hasMyKompanModule = pageElements?.some(
        (pageElement) => pageElement.type && myKompanModulesList.includes(pageElement.type),
    );

    const hasFloatingMenu = useMemo(() => {
        if (!pageElements || !pageElements.length) return false;

        const firstPageElement = pageElements[0];

        if (!firstPageElement) return false;

        return (
            page.type != 'p80SearchResultsPage' &&
            firstPageElement.type === 'm10HeroModule' &&
            (firstPageElement as M10HeroModule).mediaLayout !== 'contained' &&
            (firstPageElement as M10HeroModule).headlinePosition !== 'aboveMedia'
        );
    }, [pageElements, page.type]);

    const hasLoginRedirectModule = useMemo(() => {
        return !!pageElements.find((module) => module.type == 'myKompanLoginRedirectModule');
    }, [pageElements]);

    return (
        <PageProvider page={{ ...page, pageElements }} pageBaseUrl={pageBaseUrl}>
            <Layout
                isMyKompan={isMyKompan}
                isLocalhost={isLocalhost}
                hasMyKompanModule={hasMyKompanModule}
                hasLoginRedirectModule={hasLoginRedirectModule}
                pageBaseUrl={pageBaseUrl}
                pageUrl={pageUrl}
                hasFloatingMenu={hasFloatingMenu}
            >
                <LazyRecaptcha
                    recaptchaKey={googleRecaptchaKey ?? ''}
                    language={frame?.culture ?? 'en'}
                >
                    <InViewContextWrapper>
                        <Page page={{ ...page, pageElements }} />
                        <MarketSelectorDrawer />
                        <KompanGetQuotePanel />
                    </InViewContextWrapper>
                </LazyRecaptcha>
            </Layout>
        </PageProvider>
    );
};

/**
 * Fetch the page and the frame and return as props
 *
 * CacheControl is set based on the response header from the page request
 */
export const getDynamicPageProps = async (context: GetServerSidePropsContext) => {
    const isProductPage = productDetailPageUrlRegex.test(context.resolvedUrl.split('?')[0]);
    const { props, ...rest } = await getPageProps(
        context,
        isProductPage ? productPageAndDataResolver : pageResolver,
    );

    return {
        props,
        ...(rest && 'redirect' in rest && { redirect: rest.redirect }),
        ...(rest && 'revalidate' in rest && { revalidate: rest.revalidate }),
    };
};
