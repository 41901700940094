import React, { useMemo } from 'react';
import N20MainMenu from '~/features/navigation/components/N20MainMenu';
import N60Footer from '~/features/navigation/components/N60Footer';
import { useGalleryModal } from '~/shared/hooks/useGalleryModal/useGalleryModalState';
import { StyledBlurWrapper, StyledMain } from './styled';
import N50Breadcrumb from '$features/navigation/components/N50Breadcrumb';
import dynamic from 'next/dynamic';
import { usePage } from '../../hooks/usePage';
import { MetaFields } from '../MetaFields';
const CountryRedirect = dynamic(() => import('~/shared/components/CountryRedirect'), {
    ssr: false,
});

type Props = {
    children?: React.ReactNode;
    isMyKompan?: boolean;
    isLocalhost?: boolean;
    hasMyKompanModule?: boolean;
    hasLoginRedirectModule: boolean;
    pageBaseUrl?: string;
    pageUrl?: string;
    hasFloatingMenu: boolean;
};

export const Layout = ({
    children,
    isMyKompan,
    hasMyKompanModule,
    hasLoginRedirectModule,
    pageBaseUrl,
    pageUrl,
    hasFloatingMenu,
}: Props): JSX.Element => {
    const { visible } = useGalleryModal();
    const { pageElements, type } = usePage();
    const shouldRenderBreadcrumbs = useMemo(() => {
        if (isMyKompan) return false;
        if (pageElements?.[0]?.type === 'm10HeroModule' && type !== 'p80SearchResultsPage')
            return false;
        return true;
    }, [pageElements, type, isMyKompan]);

    if (hasLoginRedirectModule) {
        return (
            <>
                <MetaFields pageBaseUrl={pageBaseUrl} pageUrl={pageUrl} />
                <StyledMain>{children}</StyledMain>
            </>
        );
    }

    if (isMyKompan || hasMyKompanModule) {
        return (
            <StyledBlurWrapper isBlurred={visible}>
                <MetaFields pageBaseUrl={pageBaseUrl} pageUrl={pageUrl} />
                <CountryRedirect />
                <N20MainMenu isFloating={hasFloatingMenu} showLoginButton showSearch={false} />
                <StyledMain>{children}</StyledMain>
                <N60Footer />
            </StyledBlurWrapper>
        );
    }

    if (!isMyKompan && !hasMyKompanModule) {
        return (
            <StyledBlurWrapper isBlurred={visible}>
                <CountryRedirect />
                <MetaFields pageBaseUrl={pageBaseUrl} pageUrl={pageUrl} />
                {type !== 'p100RecommendationsFlows' && (
                    <N20MainMenu isFloating={hasFloatingMenu} showLoginButton={false} showSearch />
                )}
                {type !== 'p100RecommendationsFlows' && shouldRenderBreadcrumbs && (
                    <N50Breadcrumb />
                )}
                <StyledMain>{children}</StyledMain>
                {type !== 'p100RecommendationsFlows' && <N60Footer />}
            </StyledBlurWrapper>
        );
    }
    return <></>;
};
