import { useCallback, useEffect } from 'react';
import { useCookie } from 'react-use';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import {
    COUNTRY_REDIRECT_COOKIE_NAME,
    COUNTRY_REDIRECT_PERSIST_CONFIG,
    COUNTRY_REDIRECT_SELECTED_MARKET,
    COUNTRY_REDIRECT_SESSION_CONFIG,
} from '../lib';

declare global {
    interface Window {
        CookieInformation?: {
            getConsentGivenFor: (key: string) => boolean;
            _getVisitorId: () => string | false;
        };
    }
}

export const useMarketCookies = () => {
    const [ipCookieValue, _updateIpCookieValue] = useCookie(COUNTRY_REDIRECT_COOKIE_NAME);
    const [marketCookieValue, _updateMarketCookieValue] = useCookie(
        COUNTRY_REDIRECT_SELECTED_MARKET,
    );
    const { consent } = useCookieConsent();

    useEffect(() => {
        if (marketCookieValue == null) {
            return;
        }

        // Re-update value based on new consent
        updateMarketCookieValue(marketCookieValue as string);
    }, [consent?.functional]);

    const deleteCookie = (name: string) => {
        const deleteDate = new Date(Date.now() - 1);
        document.cookie = `${name}=;expires=${deleteDate.toUTCString()};path=/`;
    };

    const updateIpCookieValue = useCallback(
        (value: string | null) => {
            if (value == null) {
                deleteCookie(COUNTRY_REDIRECT_COOKIE_NAME);
            } else if (consent?.functional) {
                _updateIpCookieValue(value, COUNTRY_REDIRECT_SESSION_CONFIG);
            }
        },
        [consent?.functional],
    );

    const updateMarketCookieValue = useCallback(
        (value: string | null) => {
            if (value == null) {
                deleteCookie(COUNTRY_REDIRECT_SELECTED_MARKET);
            } else if (consent?.marketing && value == 'false') {
                _updateMarketCookieValue(value, COUNTRY_REDIRECT_SESSION_CONFIG);
            } else if (consent?.marketing) {
                const config =
                    consent?.functional == true
                        ? COUNTRY_REDIRECT_PERSIST_CONFIG
                        : COUNTRY_REDIRECT_SESSION_CONFIG;
                _updateMarketCookieValue(value, config);
            }
        },
        [consent?.marketing],
    );

    return {
        ipCookieValue,
        updateIpCookieValue,
        marketCookieValue,
        updateMarketCookieValue,
    };
};
