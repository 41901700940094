import React, { useMemo } from 'react';
import { MetaMenuSection as IMetaMenuSection } from '~/lib/data-contract';
import { Link } from '~/shared/components';
import { StyledMetaItem, StyledMetaLink, StyledMetaTitle } from './styled';
import NextLink from 'next/link';

type MetaMenuSectionProps = {
    metaMenuSection: IMetaMenuSection;
};

const MetaMenuSection = ({ metaMenuSection }: MetaMenuSectionProps) => {
    const items = metaMenuSection?.metaMenuItems ?? [];
    const hasItems = items.length > 0;
    const sectionHeadingMemo = useMemo(() => {
        if (metaMenuSection?.pageReference?.title && metaMenuSection?.pageReference?.url) {
            return (
                <li>
                    <StyledMetaTitle variant={'display6'}>
                        <NextLink
                            href={metaMenuSection.pageReference.url}
                            passHref
                            prefetch={false}
                            legacyBehavior
                        >
                            <Link>
                                <StyledMetaLink>
                                    {metaMenuSection.pageReference.title}
                                </StyledMetaLink>
                            </Link>
                        </NextLink>
                    </StyledMetaTitle>
                </li>
            );
        }

        if (metaMenuSection?.title && hasItems) {
            return <StyledMetaTitle variant="display6" children={metaMenuSection.title} />;
        }
    }, [metaMenuSection.pageReference, items, hasItems]);

    return (
        <ul>
            {sectionHeadingMemo}
            {items.map((metaMenuItem, index) => {
                if (!metaMenuItem?.url || !metaMenuItem?.title) {
                    return <></>;
                }
                return (
                    <StyledMetaItem key={`metaMenuItem_${index}`} variant={'body'}>
                        <NextLink
                            href={metaMenuItem?.url?.url}
                            passHref
                            prefetch={false}
                            legacyBehavior
                        >
                            <Link>
                                <StyledMetaLink>{metaMenuItem.title}</StyledMetaLink>
                            </Link>
                        </NextLink>
                    </StyledMetaItem>
                );
            })}
        </ul>
    );
};

export default MetaMenuSection;
