import { useSearchState } from '~/features/navigation/components/N25SearchBar';
// import { ScrollArea } from '~/shared/components';
import M140ProductsList from '$templates/blocks/components/M140ProductsList';
import { useTranslation } from '~/shared/utils/translation';
import { StyledSearchBarProductsResults, StyledViewAllButton } from './styled';
import IconArrowRight from '$icons/arrow-right.svg';
import { Button } from '~/shared/components';

type SearchBarProductsResults = {
    onClickViewAll: () => void;
};

export const SearchBarProductsResults = ({ onClickViewAll }: SearchBarProductsResults) => {
    const { searchInputValue } = useSearchState();

    const { translate } = useTranslation();

    return (
        searchInputValue !== '' && (
            // <ScrollArea type="hover">
            <StyledSearchBarProductsResults>
                <M140ProductsList
                    defaultLayout="Cards"
                    paginationType={'Continuous Loading'}
                    id={'search-bar-page'}
                    pageElementIndex={0}
                    headline={`${translate(
                        'Kompan.Search.ProductsHeadline',
                    )} (${searchInputValue})`}
                    type={'m140ProductListModule'}
                    showCategoryOverview={false}
                    showEmptyCategories={false}
                    showUserFilters={false}
                    productsPerPage={'12'}
                    showOnlyProductsList
                    showPagination={false}
                />
                <StyledViewAllButton>
                    <Button
                        disableHoverAnimation={true}
                        variant="Red"
                        icon={<IconArrowRight />}
                        iconAlignment="end"
                        onClick={onClickViewAll}
                    >
                        {translate('Kompan.Search.ViewAllProducts')}
                    </Button>
                </StyledViewAllButton>
            </StyledSearchBarProductsResults>
            // </ScrollArea>
        )
    );
};
