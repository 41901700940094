import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchState } from '~/features/navigation/components/N25SearchBar';
import { Icon, InputField, Link, useTracking } from '~/shared/components';
import {
    StyledPredictionsList,
    StyledPredictionsListItem,
    StyledPredictionsPanel,
    StyledPredictionsPanelHeadline,
    StyledSearchBarContainer,
    StyledSearchButton,
    StyledSearchClearButton,
    StyledSearchInputWrap,
} from './styled';
import ArrowRight from '$icons/arrow-right.svg';
import CloseRound from '$icons/close-round.svg';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import {
    getRelewiseSearchPredictions,
    useProductsStore,
} from '$templates/blocks/components/M140ProductsList';
import NextLink from 'next/link';
import { useCookie, useDebounce, useIsomorphicLayoutEffect, useSearchParam } from 'react-use';
import { useComputed, useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { ContentResultList, useIsSearchPage } from '$templates/blocks/components/SearchResults';
import { useQuery } from 'react-query';
import { useRouter } from 'next/router';
import { usePage } from '$templates/pages';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import { LAST_SEARCH_COOKIE_NAME, LastSearch } from '../LastSearch';
import { SearchBarPredictions } from './SearchBarPredictions';
import { SearchBarProductsResults } from './SearchBarProductsResults';
import { ContentResultListType } from '../ContentResultList';
import { PopularSearches } from '../PopularSearch/PopularSearches';
import { saveSearchTerm } from '../../utils/saveSearchedTerm';

export type SearchBarProps = {
    showPredictions?: boolean;
    showLastSearch?: boolean;
};
export type SearchBarInputs = {
    search: string | null;
};

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>(
    ({ showPredictions: showPredictionsComp = false, showLastSearch = false }, ref) => {
        const { trackInternalSearch } = useTracking();
        const {
            setQuery,
            setIsOpen,
            setIsFocused,
            // isFocused,
            isOpen,
            setSearchInputValue,
        } = useSearchState();
        const { products, setSelectedCategories } = useProductsStore();
        const searchParam = useSearchParam('search');
        const { push } = useRouter();
        const [LastSearchCookieValue, _updateLastSearchCookieValue] =
            useCookie(LAST_SEARCH_COOKIE_NAME);
        const {
            register,
            watch,
            handleSubmit,
            trigger,
            setValue,
            formState: { isSubmitting },
            getValues,
        } = useForm({
            defaultValues: {
                search: searchParam,
            },
        });

        useEffect(() => {
            if (searchParam) {
                trigger('search');
            }
        }, []);

        const { translate } = useTranslation();
        const { data: frameData } = useFrame();
        const { market = 'INT', culture = 'en' } = usePage();
        const { getUserObject } = useRelewiseTracking();
        const shortcuts =
            frameData?.searchShortCuts?.searchShortCuts?.filter((link) => !!link.url) || [];
        const localeString = `${culture}-${market}`.toLowerCase();
        const { isSearchPage, searchUrl } = useIsSearchPage();
        const watchValue = watch('search');
        // Debounced search value to limit requests to API.
        const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

        useEffect(() => {
            setSearchInputValue(watchValue ?? '');
            setQuery(watchValue ?? '');
        }, [watchValue]);

        const { data } = useQuery(
            ['relewise-search-term-prediction', debouncedSearchValue, 5, localeString],
            () =>
                getRelewiseSearchPredictions({
                    term: debouncedSearchValue || '',
                    take: 5,
                    locale: localeString,
                    user: getUserObject(),
                }),
            {
                keepPreviousData: true,
                enabled: showPredictionsComp && isOpen,
                cacheTime: 1000 * 60 * 60, // 1 hour cache time
                staleTime: 1000 * 60 * 60, // 1 hour cache time,
                retryOnMount: false,
            },
        );

        const shouldShowClear = useComputed<boolean>(
            () => {
                return !!watchValue;
            },
            [watchValue],
            false,
        );
        useIsomorphicLayoutEffect(() => {
            if (watchValue !== searchParam) {
                setSearchInputValue(searchParam ?? '');
                setQuery(searchParam ?? '');
                trigger('search');
            }
            // If we receive a searchParam, reset category selection.
            // If searchParam is empty we have moved away from search page.
            if (searchParam) {
                setSelectedCategories([]);
            }
            setQuery(searchParam ?? '');
        }, [searchParam]);

        const onSubmit: SubmitHandler<SearchBarInputs> = (data) => handleFormSubmit(data);

        const handleViewAll = () => {
            const search = getValues()?.search;

            saveSearchTerm({
                searchTermValue: search ?? '',
                LastSearchCookieValue,
                _updateLastSearchCookieValue,
            });

            const newUrl = `${searchUrl}?search=${search}`;
            push(newUrl);
            setIsOpen(false);
        };

        const handleFormSubmit = async (payload?: SearchBarInputs) => {
            const data = payload ?? getValues();

            if (isSubmitting) return;
            trackSearchQuery();

            saveSearchTerm({
                searchTermValue: data?.search ?? '',
                LastSearchCookieValue,
                _updateLastSearchCookieValue,
            });

            if (isSearchPage) {
                // if we're already on search page, we should use existing params if filters have been set.
                const params = new URLSearchParams(window.location.search);
                params.set('search', data.search || '');
                // Clear selected categories so they don't interfere with new search
                params.delete('categories');
                const newUrl = params.toString()
                    ? `${searchUrl}?${params.toString()}`
                    : (searchUrl as string);
                push(newUrl);
            } else {
                const currentMarket = `${culture}/${market.toLowerCase()}`;
                const searchTerm = payload?.search?.toLocaleLowerCase();
                const productId = products?.[0]?.productId?.toLocaleLowerCase();
                const newUrl =
                    searchTerm === productId
                        ? `/${currentMarket}/p/${products?.[0].productId}`
                        : `${searchUrl}?search=${data.search}`;
                push(newUrl.toLocaleLowerCase());
                setIsOpen(false);
            }
        };

        const clearSearch = () => {
            setValue('search', '');
        };

        useDebounce(() => handleChange(), 500, [watchValue]);

        const handleChange = async () => {
            if (!showPredictionsComp) return;
            // if (!isFocused) return;
            if (watchValue?.length && watchValue.length > 2) {
                setDebouncedSearchValue(watchValue);
            } else {
                setDebouncedSearchValue('');
            }
        };

        const wrapSearchTerm = (str: string, search = '') => {
            const result = str.replace(
                new RegExp(search.trim(), 'gi'),
                '<em class="match">$&</em>',
            );
            return result;
        };

        const trackSearchQuery = () => {
            if (watchValue) {
                trackInternalSearch({
                    query: watchValue,
                });
            }
        };

        const showShortcuts =
            !!shortcuts.length &&
            (`${watchValue}`.length < 4 ||
                !data?.predictions?.length ||
                data?.predictions?.length === 0);

        const showPredictions =
            (watchValue?.length ?? 0) > 2 &&
            !!data?.predictions?.length &&
            data.predictions.length > 0;

        return (
            <StyledSearchBarContainer ref={ref}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <StyledSearchInputWrap>
                        <InputField
                            isFocused={isOpen}
                            id={'search'}
                            {...register('search', { required: true })}
                            maxLength={256}
                            value={watch('search') ?? ''}
                            placeholder={translate('Kompan.Search.SearchInputPlaceholder')}
                            helpText={''}
                            autoComplete={'off'}
                            onFocus={() => {
                                setIsFocused(true);
                                watchValue && setDebouncedSearchValue(watchValue);
                            }}
                            onBlur={() => setIsFocused(false)}
                        />
                        <StyledSearchClearButton
                            show={shouldShowClear || false}
                            type="button"
                            disabled={false}
                            onClick={clearSearch}
                            aria-label={translate('Kompan.Search.Clear')}
                        >
                            <Icon size="md">
                                <CloseRound />
                            </Icon>
                        </StyledSearchClearButton>

                        <StyledSearchButton
                            type="submit"
                            aria-label={translate('Kompan.Search.Search')}
                        >
                            <Icon>
                                <ArrowRight />
                            </Icon>
                        </StyledSearchButton>
                    </StyledSearchInputWrap>
                </form>

                {showLastSearch && (
                    <LastSearch handleClick={(term: string) => setValue('search', term)} />
                )}
                {showPredictionsComp && (
                    <StyledPredictionsPanel>
                        {!showPredictions && (
                            <PopularSearches
                                handleClick={(term: string) => setValue('search', term)}
                            />
                        )}
                        {showPredictions && (
                            <>
                                <div>
                                    <SearchBarPredictions
                                        predictions={data?.predictions}
                                        translationHeading={translate('Kompan.Search.Suggestions')}
                                        isSearchPage={isSearchPage}
                                        searchUrl={searchUrl ?? ''}
                                        trackSearchQuery={trackSearchQuery}
                                        watchValue={watchValue}
                                        wrapSearchTerm={wrapSearchTerm}
                                    />
                                    <ContentResultList
                                        type={ContentResultListType.LIST}
                                        perPage={12}
                                    />
                                </div>
                                <SearchBarProductsResults onClickViewAll={handleViewAll} />
                            </>
                        )}
                        {showShortcuts && (
                            <Fragment>
                                ShortCuts
                                <StyledPredictionsPanelHeadline variant="captionSm">
                                    {translate('Kompan.Search.Shortcuts')}
                                </StyledPredictionsPanelHeadline>
                                <StyledPredictionsList>
                                    {shortcuts.map((item, index) => (
                                        <StyledPredictionsListItem
                                            type="shortcut"
                                            key={`shortcuts_${index}`}
                                        >
                                            <NextLink
                                                prefetch={false}
                                                href={`${item?.url}`}
                                                passHref
                                                shallow={isSearchPage}
                                                legacyBehavior
                                            >
                                                <Link>{item?.title}</Link>
                                            </NextLink>
                                        </StyledPredictionsListItem>
                                    ))}
                                </StyledPredictionsList>
                            </Fragment>
                        )}
                    </StyledPredictionsPanel>
                )}
            </StyledSearchBarContainer>
        );
    },
);

SearchBar.displayName = 'SearchBar';
